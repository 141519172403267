<template>
  <div class="overflow-hidden bg-white py-20 shadow sm:rounded-lg">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ElCard'
}
</script>
